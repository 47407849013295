import React from 'react'
import Announcement from '../Announcement'

import { industryPageAnnouncements } from "./IndustryPageAnnouncements.module.css"

const IndustryPageAnnouncements = ({announcements}) => {
    return (
        <div className={industryPageAnnouncements}>
            {announcements.map( announcement => {
                return(
                    <Announcement
                        title={announcement.title}
                        date={announcement.date}
                        linkTo={"/announcements/" + announcement.slug}
                    />
                )
            })}
        </div>
    )
}

export default IndustryPageAnnouncements
