import React from 'react'
import {
    testimonial,
    testimonial_image,
    testimonial_quote,
    testimonial_name,
    testimonial_title,
} from "./Testimonial.module.css"

const Testimonial = ( props ) => {
    return (
        <div className={testimonial}>
            {props.image && <img src={props.image} alt={props.name} className={testimonial_image}/>}
            <div>
                <p className={testimonial_quote}>{props.quote}</p>
                <div>
                    <span className={testimonial_name}>{props.name}</span>
                    {props.title && <span className={testimonial_title}>{props.title}</span>}
                </div>
            </div>
        </div>
    )
}

export default Testimonial
